import React, {Fragment, useState} from 'react';
import { Input } from '@chakra-ui/input';
import {
    Heading,
    Center,  
    Box, 
    Text, 
    FormControl, 
    FormLabel, 
    FormErrorMessage, 
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    SimpleGrid,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Document,Page } from 'react-pdf/dist/esm/entry.webpack';
import { pdfjs } from 'react-pdf';
import CertificateAPI from '../../services/CertificateAPI';
import { useParams } from 'react-router';
import { useWindowWidth } from '@wojtekmaj/react-hooks';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;


let defaultValues = {
    identity: ''
}
const schema = yup.object({
    identity: yup.string().required('Identitas tidak boleh kosong'),
})


const CertificateDownload = () => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [pageNumber] = useState(1)
    const [modalSize, setModalSize] = useState('')
    const [modalData, setModalData] = useState({ isLoading: false, status: false, urlDownloadPdf: '', urlDownloadWord: '', urlPreviewWord: '' })
    const {id} = useParams()
    const width = useWindowWidth()

    function onClose() {
        setIsModalOpen(false)
    }
    const { register, handleSubmit, formState:{ errors } } = useForm({
        resolver: yupResolver(schema),
        shouldUnregister: false,
        defaultValues,
    })
    function downloadPdf() {
        window.location.href=modalData.urlDownloadPdf
    }

    const onSubmit = data => {
        const params = {
            'id': id,
            'identity': data.identity
        }
        try {
            setModalData({ ...modalData, isLoading: true })
            CertificateAPI.checkDataCertificate(params).then(response => {
                if (response.code === 200) {
                    setModalSize('full')
                    setIsModalOpen(true)
                    setModalData({status: true, isLoading: false, urlDownloadPdf: response.data.exportData.pdf.downloadURL, urlDownloadWord: response.data.exportData.word.downloadURL, urlPreviewWord: response.data.exportData.word.previewURL })
                } else {
                    setModalSize('sm')
                    setIsModalOpen(true)
                    setModalData({...modalData, status: false, isLoading: false})
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Fragment>
            <Center w="100vw" h="100vh" color="gray.50" className="guest-container">
                <SimpleGrid
                    column="1"
                    mt={24}
                    mb={12}
                    textAlign="center"
                >
                    <Heading as="h2" size="3xl" textAlign="center">
                        Cetak Sertifikat
                    </Heading>
                    <Text fontSize="xl">Silahkan isi data dibawah untuk cetak sertifikat.</Text>
                    <Box bg="gray.50" padding="8" borderRadius="xl" shadow="2xl">
                        <FormControl id="nameInputCertificate" isInvalid={!!errors?.identity} mt="4">
                            <FormLabel color="gray.800">
                                E-mail <span style={{ color: 'red' }}>*</span>
                            </FormLabel>
                            <Input
                                fontWeight="medium"
                                bg="gray.200"
                                {...register('identity')}
                                color="gray.700"
                                border="none"
                                type="text"
                                placeholder="Masukan email"
                            />
                            <FormErrorMessage>{errors.identity?.message}</FormErrorMessage>
                        </FormControl>
                    </Box>
                    <Box textAlign="center">
                        <Button
                            marginTop="32px !important"
                            onClick={handleSubmit(onSubmit)}
                            loadingText="Memproses"
                            bg="pink.500"
                            size="lg"
                            _hover={{ bg: 'pink.600' }}
                            _active={{
                                bg: 'pink.500',
                            }}
                            _focus={{
                                outline: 'none',
                            }}>
                            {modalData.isLoading?'Sedang Memeriksa...':'Lanjut'}
                        </Button>
                    </Box>
                </SimpleGrid>
            </Center>
            <Modal closeOnOverlayClick={false} size={modalSize} isOpen={isModalOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Cetak Sertifikat</ModalHeader>
                    <ModalCloseButton />
                        {
                            modalData.status?
                                <Fragment>
                                    <ModalBody pb={6}>
                                        <Center m={2}>
                                            <Document
                                                    file={modalData.urlDownloadPdf}
                                                    renderMode="canvas"
                                            >
                                                    <Page pageNumber={pageNumber} width={(width * 0.9 > 768) ? width * 0.5 : (width * 0.9 > 480) ? width * 0.8 : width * 0.9} />
                                            </Document>
                                        </Center>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button mr={4} 
                                            onClick={()=>downloadPdf()}
                                        >
                                            Download PDF
                                        </Button>
                                    </ModalFooter>
                                </Fragment>:
                                <Fragment>
                                    <ModalBody pb={6}>
                                        <Text mb='1rem'>
                                            Data anda tidak ditemukan
                                        </Text>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button onClick={onClose}>Tutup</Button>
                                    </ModalFooter>
                                </Fragment>

                        }
                </ModalContent>
            </Modal>
        </Fragment>
    )
}

export default CertificateDownload;