import { Heading, Center, Box, Text, Badge, SimpleGrid } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { CertificateContext } from '../../context/CertificateContext';
import { useContext, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComment, faVolumeUp, faCalendar } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'


const HomePage = () => {
	const topicContext = useContext(CertificateContext)
	const { fetchTopicList, topicList } = topicContext
	const [parameters] = useState({
		currentPage: 1,
		pageSize: 10,
		sort: 'name',
		order: 'desc',
	})

	useEffect(() => {
		fetchTopicList(parameters)
	}, [parameters])


	return (
		<Center w="100%" h="100%" color="gray.50" className="guest-container">
			<SimpleGrid
				column="1"
				mt={24}
				mb={12}
			>
				<Heading as="h2" size="xl" textAlign="center">
					Selamat Datang, Silahkan Pilih Agenda:
				</Heading>
				<SimpleGrid
					columns={{ sm: 1, md: 3 }}
					spacing="6"
					p="10"
					textAlign="center"
					rounded="lg"
					color="gray.400"
				>
					{topicList.isLoading ? null : topicList.data.map((data) => (
						<Link to={`/${data.ID}/certificate`} key={data.ID}>
							<Box bg="gray.200" maxW="sm" borderWidth="1px" borderRadius="lg" overflow="hidden" opacity={moment(data.date, 'YYYY-MM-DD').isSameOrBefore(moment(), 'day') ? 1.0 : 0.4}>
								<Box p="6" maxWidth="43vh">
									<Box maxH="30vh" display="flex">
										<div>
											<Center fontSize="1em" borderRadius="xl" px="2" color="teal.50" bg="teal.400">
												New
											</Center>
										</div>
										<SimpleGrid
											color="gray.500"
											fontWeight="semibold"
											letterSpacing="wide"
											fontSize="xs"
											textAlign="left"
											ml="2"
											isTruncated
										>
											<span>
												<FontAwesomeIcon icon={faComment} />
												<span style={{ paddingLeft: 5 }}>{data.moderator.name}</span>
											</span>
											{data.speakers.map((speaker) => (
												<div key={speaker.ID} >
													<FontAwesomeIcon icon={faVolumeUp} />
													<span style={{ paddingLeft: 5 }}>{speaker.name}</span>
												</div>
											))}
										</SimpleGrid>
									</Box>

									<Box
										mt="3"
										fontWeight="semibold"
										as="h4"
										lineHeight="tight"
										color="teal.900"
									>
										<Text isTruncated>
											{data.name}

										</Text>
									</Box>

									<Box>
										<FontAwesomeIcon icon={faCalendar} />
										<small style={{ paddingLeft: 3 }}>{moment(data.date, 'YYYY-MM-DD').format('dddd, D MMM YYYY')}</small>
									</Box>
								</Box>
							</Box>
						</Link>
					))}
				</SimpleGrid>
			</SimpleGrid>
		</Center >
	)
}

export default HomePage;