import queryString from 'query-string';
const BASE_URL = process.env.REACT_APP_BASE_URL_API;


class CertificateAPI {
	static checkDataTopic = async({ params={} }) => {
		console.log( "DEBUG:API:PARAMS", params )
		return fetch(`${BASE_URL}/module-certification/topic?${queryString.stringify(params)}`
		).then(response => response.json())
	}

	static checkDataCertificate({ id, identity }) {
		console.log( "DEBUG:API:ID", id )
		console.log( "DEBUG:API:IDENTITY", identity )
		const formData = new FormData()
		formData.append('topicID', id)
		formData.append('email', identity)

		return fetch(`${BASE_URL}/certification/export?format=pdf`, {
			method: 'POST',
			body: formData,
		}).then(response => response.json())
	}

}

export default CertificateAPI;