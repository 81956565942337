// import HomePage from '@pages/Home'
import HomePage from './pages/Home/index';
import CertificateDownload from './pages/Certificate/CertificateDownload';
import './index.css';
import { BrowserRouter as Router, Route } from 'react-router-dom';


const App = () => {
  return (
    <Router>
      <Route exact path='/' component={HomePage} />
			{/* <Route exact path='/certificate' component={CertificateDownload} /> */}
			<Route exact path='/:id/certificate' component={CertificateDownload} />
    </Router>
  )
}

export default App;
