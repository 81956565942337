import React, { createContext, useState } from 'react';
import CertificateAPI from '../services/CertificateAPI';

export const CertificateContext = createContext()
export const CertificateContextConsumer = CertificateContext.Consumer

const CertificateContextProvider = ({ children }) => {
    const [ topicList, setTopicList ] = useState({ data: [], isLoading: true  })

    const fetchTopicList = async ( params = {} ) => {
		try {
			setTopicList({ ...topicList, isLoading: true })
            console.log('DEBUG:CONTEXT:PARAMS', params)
			const response = await CertificateAPI.checkDataTopic(params)
            console.log('DEBUG:CONTEXT:RESPONSE', response)
			if (response.code === 200) {
				setTopicList({ data: response.data.list, isLoading: false })
			}
		} catch (error) {
			console.log(error)
		}
	}

    return (
        <CertificateContext.Provider
            value={{ 
                fetchTopicList,
                topicList,
             }}>
                {children}
        </CertificateContext.Provider>
    )
}

export default CertificateContextProvider;